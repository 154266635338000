import React from 'react';
import Layout from '../components/layout';
import Video from '../components/video';
import Stats from '../components/stats';
import SEO from '../components/seo';
import A80 from '../animations/a80';
import A160 from '../animations/a160';
import A400 from '../animations/a400';
import MilkLink from '../components/milk-link';
import Svg from '../components/svg';
import Qualities from '../components/qualities';
import ProductSwiper from '../components/product-swiper';

export default ({ pageContext: { content, pages, lang, texts, langs } }) => {
  const data = content.data;
  const historyItems = data.history_items;
  return (
    <Layout pages={pages} lang={lang} texts={texts} langs={langs}>
      <SEO
        description={data.seo_description}
        title={data.seo_title}
        name='company'
        image={data.seo_image}
      />
      <div className='layer-top'>
        <A160 className='company-liquid-1' />
        <A400 className='company-liquid-2' />
        <div className='mid-wrap'>
          <h1
            data-sal='slide-up'
            data-sal-duration='1000'
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <div className='flex flex-2 company-1'>
            <div className='left'>
              <h3
                className='h3'
                data-sal='slide-right'
                data-sal-duration='1000'
                dangerouslySetInnerHTML={{ __html: data.story_subtitle_1 }}
              />
              <img
                // data-sal='slide-right'
                // data-sal-duration='1000'
                src={data.story_image_1}
                alt=''
                data-sal='slide-up'
                data-sal-duration='1000'
              />
            </div>
            <div className='right'>
              <div className='facts'>
                <div className='fact'>
                  <div className='fact__left'>
                    <div className='fact__overlay' />
                    <div
                      className='fact__number'
                      data-sal='fade'
                      data-sal-duration='1000'
                    >
                      1
                    </div>
                    <div
                      className='fact__line'
                      data-sal='fade'
                      data-sal-duration='1000'
                      data-sal-delay='250'
                    />
                  </div>
                  <div className='fact__right'>
                    <div className='fact__text-container'>
                      <div
                        className='large-text'
                        data-sal='slide-left'
                        data-sal-duration='1000'
                        dangerouslySetInnerHTML={{
                          __html: data.story_fact_1,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='fact'>
                  <div className='fact__left'>
                    <div className='fact__overlay' />
                    <div
                      className='fact__number'
                      data-sal='fade'
                      data-sal-duration='1000'
                      data-sal-delay='500'
                    >
                      2
                    </div>
                    <div
                      className='fact__line'
                      data-sal='fade'
                      data-sal-duration='1000'
                      data-sal-delay='750'
                    />
                  </div>
                  <div className='fact__right'>
                    <div className='fact__text-container'>
                      <div
                        className='large-text'
                        data-sal='slide-left'
                        data-sal-duration='1000'
                        data-sal-delay='250'
                        dangerouslySetInnerHTML={{
                          __html: data.story_fact_2,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='fact'>
                  <div className='fact__left'>
                    <div className='fact__overlay' />
                    <div
                      className='fact__number'
                      data-sal='fade'
                      data-sal-duration='1000'
                      data-sal-delay='1000'
                    >
                      3
                    </div>
                  </div>
                  <div className='fact__right'>
                    <div className='fact__text-container'>
                      <div
                        className='large-text'
                        data-sal='slide-left'
                        data-sal-duration='1000'
                        data-sal-delay='500'
                        dangerouslySetInnerHTML={{
                          __html: data.story_fact_3,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Video
                videoBtn={data.video_btn}
                videoImage={data.video_image}
                youtubeId={data.youtube_id}
              />
            </div>
          </div>
          <div className='flex flex-2 company-2'>
            <A160 className='company-liquid-3' />
            <div
              data-sal='slide-right'
              data-sal-duration='1000'
              className='left'
            >
              <h3
                className='h3'
                dangerouslySetInnerHTML={{ __html: data.story_subtitle_2 }}
              />
              <MilkLink className='btn' to={`/${lang}${data.story_btn_url}`}>
                {data.story_btn}
              </MilkLink>
            </div>
            <div
              data-sal='slide-left'
              data-sal-duration='1000'
              className='right'
            >
              <div
                className='text'
                dangerouslySetInnerHTML={{ __html: data.story_text }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className='layer'>
        <A80 className='company-liquid-3' />
        <A400 className='company-liquid-4' />
        <A160 className='company-liquid-5' />
        <div className='small-wrap'>
          <div
            data-sal='fade'
            data-sal-duration='1000'
            className='layer-label'
            dangerouslySetInnerHTML={{ __html: data.technologies_section }}
          />
          <h2
            data-sal='slide-up'
            data-sal-duration='1000'
            dangerouslySetInnerHTML={{ __html: data.technologies_title }}
          />
          <div className='group group-3 group-about'>
            <div className='left'>
              <img
                data-sal='slide-right'
                data-sal-duration='1000'
                src={data.technologies_image_1}
                alt=''
              />
              <img
                data-sal='slide-right'
                data-sal-duration='1000'
                className='desktop-hide'
                src={data.technologies_image_2}
                alt=''
              />
              <div
                data-sal='slide-right'
                data-sal-duration='1000'
                className='h3'
                dangerouslySetInnerHTML={{ __html: data.technologies_subtitle }}
              />
            </div>
            <div className='right'>
              <img
                data-sal='slide-left'
                data-sal-duration='1000'
                className='mobile-hide'
                src={data.technologies_image_2}
                alt=''
              />
              <div
                data-sal='slide-left'
                data-sal-duration='1000'
                dangerouslySetInnerHTML={{ __html: data.technologies_text }}
              />
            </div>
          </div>
        </div>
      </div> */}
      <Stats data={texts} />
      <div className='layer-history'>
        <div className='wrap-full pl-xl pr-xl'>
          <h2
            data-sal='slide-right'
            data-sal-duration='1000'
            dangerouslySetInnerHTML={{ __html: data.history_title }}
          />
        </div>
        <div
          className='wrap-full pl-s pr-s history'
          data-sal='fade'
          data-sal-duration='1000'
        >
          {historyItems.map((item, index) => (
            <div
              className={`history__item item-${index + 1}`}
              key={item.history_item_date}
            >
              <div className='history__item-text'>
                <div className='history__date'>{item.history_item_date}</div>
                <div
                  className='history__text'
                  dangerouslySetInnerHTML={{ __html: item.history_item_text }}
                />
              </div>
              <div className='history__item-line-container'>
                <div className='history__item-line'></div>
              </div>
              <div className='history__item-marker-container'>
                <div className='history__item-marker'></div>
              </div>
            </div>
          ))}
          <div className='svg-container mobile-hide'>
            <Svg name='history' />
          </div>
          <div className='svg-container desktop-hide'>
            <Svg name='history-mobile' />
          </div>
        </div>
      </div>
      <div id='map'>
        <A80
          className='company-liquid-7'
          opacity='0.1'
          style1={{ stopColor: '#e8efff', stopOpacity: '0' }}
          style2={{ stopColor: '#e8efff' }}
        />
        <A400
          className='company-liquid-8'
          opacity='0.1'
          style1={{ stopColor: '#E8EFFF', stopOpacity: '0' }}
          style2={{ stopColor: '#E8EFFF' }}
        />
        <div className='small-wrap'>
          <h2
            data-sal='slide-up'
            data-sal-duration='1000'
            dangerouslySetInnerHTML={{ __html: data.map_text }}
          />
        </div>

        <svg
          data-sal='fade'
          data-sal-duration='1000'
          className='desktop-hide svg'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 420 271.94'
        >
          <path
            id='map-2'
            opacity='0.1'
            fill='#E8EFFF'
            d='M117.83,271.84c-8.16-3.31-14.25-10.81-16.85-19.23s-1.94-17.62,0.78-26
                	c1.46-4.48,3.5-8.82,4.25-13.47s-0.03-9.9-3.45-13.14c-3.7-3.5-7.79-5.33-11.2-9.11c-1.96-1.93-3.51-4.24-4.54-6.79
                	c-0.87-2.6-0.56-5.78,1.46-7.64c1.16-1.07,2.85-1.72,3.41-3.2c0.69-1.83-1.55-4.04-0.98-5.91c-2.5,0.2-4.94-0.83-6.55-2.76
                	c-0.52-0.75-1.08-1.48-1.69-2.16c-2.55-2.47-7.2-1.44-9.72-3.94c-0.52-0.52-0.92-1.16-1.46-1.65c-1.18-1.07-2.87-1.3-4.44-1.58
                	c-3.02-0.55-6.96-1.23-9.37-3.14c-3.32-2.65-5.72-6.28-6.86-10.38c-0.65-2.35-0.87-4.8-1.6-7.12c-1.65-4.68-5-8.57-9.38-10.9
                	c-4.06-2.22-8.05-5.68-8.47-10.29c-0.23-2.54,0.67-5.05,0.78-7.6c0.06-3.43-0.93-6.81-2.85-9.65c-1.75-2.48-2.07-5.66-2.33-8.69
                	c-0.37-2.44-0.26-4.94,0.32-7.34c0.78-2.51,2.55-4.57,3.78-6.9c1.23-2.33,1.88-5.32,0.38-7.48c-1.12-1.62-3.15-2.33-5.07-2.76
                	c-5.57-1.25-12.4-3.14-16.8,0.5c-1.67,1.38-4.14,0.91-6.24,0.37c-0.98-0.25-2.1-0.63-2.45-1.58c-0.15-0.88-0.01-1.78,0.4-2.57
                	C1.8,57.5,1.57,54.4,0.1,52.6c-0.24-2.68-0.22-5.93,2.05-7.37c0.77-0.39,1.55-0.73,2.35-1.04c0.82-0.32,1.39-1.07,1.5-1.94
                	c0.01-1.08-1.05-1.81-1.68-2.69c-0.8-1.13,0.03-1.76,0.72-2.97c-0.39-0.98,0.49-2.07,1.49-2.38c1.03-0.2,2.1-0.22,3.14-0.06
                	c1.88,0.2,3.79-0.07,5.54-0.78c0.31-1.95-1.33-3.58-2.65-5.04s-3.04-4.55-1.51-5.8c3.46-2.82,8.37-4.32,12.68-3.16
                	c3.4-0.77,9.12,0.58,10.08,3.93c0.6,2.1,2.87,3.14,4.81,4.12c2.96,1.55,5.68,3.53,8.06,5.87c2.27-0.15,5.36-0.6,7.01-2.16
                	c1.88-0.19,3.78,0.06,5.56,0.73c1.69,0.42,3.45,0.55,5.18,0.38c2.38-0.06,4.8-0.13,6.21,0.77c3.92,2.5,6.28,7.45,10.78,8.59
                	c1.62,0.28,3.27,0.39,4.91,0.33c5.61,0.25,11.27,3.48,13.37,8.69s-0.04,11.87-5.32,13.8c-4.44-1.54-9.28,0.82-10.81,5.25
                	s0.82,9.28,5.25,10.81c0.32,0.11,0.65,0.2,0.99,0.28c1.34,0.14,2.66,0.43,3.94,0.86c1.59,0.75,2.57,2.35,3.81,3.6
                	c0.82,0.94,1.96,1.53,3.19,1.66c2.36,0.06,3.99-2.54,4.06-4.9s-0.9-4.61-1.28-6.94s0.07-5.11,2.1-6.31c2.08-1.23,4.9-0.12,6.37,1.8
                	s1.92,4.4,2.33,6.78c2.08-1.07,4.91-1,7.24-1.11c1.49,1.77,1.62,4.26,2.08,6.53c0.5,2.47,1.47,4.83,2.84,6.95
                	c1.13,1.43,2.06,3.02,2.74,4.72c0.86,3.62-1.37,7.25-4.99,8.11c-0.25,0.06-0.5,0.1-0.75,0.13c-0.94,0.03-1.87-0.03-2.8-0.18
                	c-1.78-0.23-3.52-0.46-4.92,0.54c-1.43,1.03-1.83,2.94-2.72,4.45c-1.24,2.09-2.49,3.62-4.92,3.52c-2.8-0.12-5.59,0.39-8.18,1.47
                	c-0.98,0.29-1.79,0.98-2.23,1.9c-0.41,1.22,0.41,2.47,0.77,3.7c0.53,2.38-0.24,4.86-2.04,6.51c-1.69,1.68-3.37,4.06-5.75,4.04
                	c-2.35,0.02-4.43,1.53-5.18,3.76c-0.3,0.94-0.32,1.95-0.54,2.91c-0.14,0.99-0.69,1.88-1.52,2.45c-0.86,0.49-2.44,0.38-2.45-0.61
                	c-0.04-3.9-4.16-6.75-8.05-6.86c-4.08-0.16-7.85,2.12-9.62,5.8c-1.56,3.66-0.1,8.49,3.45,10.27c1.89,0.78,3.93,1.15,5.98,1.07
                	c2.05-0.08,4.09,0.27,6,1.02c1.84,0.88,3.35,2.8,3.08,4.83c-0.17,1.27-0.92,2.8,0.04,3.65c0.58,0.51,1.47,0.4,2.18,0.7
                	c1.33,0.55,1.65,2.26,2.42,3.48c1.08,1.7,4.59,2.26,6.49,1.61c0.33-4.16,5.6-7.03,9.27-5.05c1.77,0.96,3.36,2.82,5.34,2.44
                	c0.7-0.22,1.38-0.49,2.04-0.8c1.76-0.58,3.66,0.21,5.35,0.99c4.47,2.06,9.09,4.23,12.27,7.99c1.88,2.23,3.23,4.98,5.61,6.67
                	c2.6,1.84,5.97,2.08,9.16,2.17s6.52,0.13,9.3,1.68s4.77,5.21,4.05,8.32c-0.63,2.73-1.82,5.29-3.51,7.52
                	c-1.42,1.88-3.26,3.69-3.48,6.04c-0.16,1.67,0.55,3.29,0.83,4.94c0.28,1.35,0.11,2.75-0.48,3.99c-0.63,1.21-1.53,2.46-2.87,2.68
                	c-1.21,0.15-2.43,0.17-3.64,0.07c-2.25-0.11-4.46-0.21-6.11,1.2c-2.76,2.38-1.44,6.8-1.74,10.44c-0.38,4.55-3.76,8.46-7.87,10.44
                	c-1.01,0.37-1.94,0.94-2.74,1.68c-1.54,1.74-1.2,4.99-3.32,5.92c-0.65,0.29-1.39,0.26-2.06,0.49c-1.46,0.64-2.39,2.09-2.36,3.69
                	c-0.05,1.55,0.85,3.07,0.89,4.62c0.04,1.67-0.51,3.29-1.56,4.59c-0.69,0.84-1.62,1.63-1.71,2.71c0.05,0.98,0.46,1.9,1.16,2.59
                	c1.91,2.42,4.53,4.74,6,7.45c0.46,0.89,0.32,1.97-0.34,2.71c-0.53,0.62-1.29,1.01-2.11,1.09
                	C118.2,271.94,118.01,271.91,117.83,271.84z M396.26,239.6c-2.48-3.87-2.86-9.45-6.9-11.65c-3.77-2.06-8.34,0.15-12.12,2.2
                	s-8.65,3.9-12.08,1.32c-0.79-0.52-1.31-1.37-1.4-2.31c0.08-0.77,0.31-1.52,0.68-2.21c1.41-3.6-0.36-7.55-1.21-11.31
                	s0.02-8.82,3.78-9.7c1.28-0.3,2.64-0.01,3.92-0.31c2.16-0.52,3.55-2.54,5.02-4.2c3.2-3.59,9.09-7.16,14.12-6.51
                	c4.14,0.53,7.14,6.05,10.86,5.92c-1.29-5.62,3.45-5.2,5.78-1.23c1.03,2.06,1.63,4.3,1.79,6.59c5.7,4.36,12.02,10.09,11.18,17.22
                	c-0.43,3.69-2.77,6.84-5.03,9.79c-1.71,2.23-3.42,4.47-5.12,6.7c-0.77,1.14-1.74,2.13-2.85,2.94c-0.95,0.6-2.05,0.91-3.17,0.9
                	C400.56,243.63,397.86,242.08,396.26,239.6z M234.03,226.88c-1.08,0.01-2.15-0.26-3.1-0.79c-1.95-1.3-1.95-4.09-2.37-6.4
                	c-1.57-8.59-11.49-15.45-9.12-23.85c0.52-1.83,1.62-3.54,1.66-5.44c0.08-3.68-3.63-6.13-5.62-9.23c-1.85-2.94-2.19-6.58-0.92-9.8
                	c-1.11,1.34-3.34,0.79-4.69-0.32s-2.37-2.67-3.99-3.34s-3.47-0.28-5.21-0.06c-7.62,0.96-15.84-1.93-20.49-8.04
                	s-5.05-15.4-0.26-21.42c4.4-5.52,12.29-7.89,15.52-14.17c5.05,3.51,11.25-2.79,17.36-3.49c1.41-0.16,3.06,0.12,3.79,1.33
                	c0.37,0.92,0.59,1.9,0.64,2.9c0.77,3.62,5.3,5.05,8.98,4.62s7.25-2.01,10.93-1.64c3.33,0.33,6.39,2.25,9.74,2.35
                	s7.14-3.28,5.46-6.18c-1.23-2.11-3.87-1.94-6.55-1.78c-2.58,0.16-5.19,0.32-6.62-1.53c-1.01-1.31-0.95-3.15-1.66-4.64
                	s-3.17-2.36-3.85-0.85c0.98,1.68,0.42,3.84-1.26,4.83c-0.27,0.16-0.55,0.28-0.85,0.36c-1.48-6.44-6.28-11.61-12.6-13.55
                	c-1.04,1.6,0.07,3.73,1.4,5.1s3,2.68,3.25,4.58s-2.35,3.84-3.52,2.33c-0.46-4.18-2.92-7.87-6.6-9.91c-0.85,1.39-2.8,1.42-4.38,1.78
                	c-2.87,0.69-5.14,2.88-5.95,5.72c-0.34,1.25-0.4,2.63-1.21,3.64c-0.95,0.98-2.26,1.53-3.63,1.52c-3.36,0.26-7.07-0.86-8.87-3.71
                	s-0.66-7.43,2.6-8.29c1.89-0.5,3.85,0.28,5.79,0.42s4.36-0.87,4.38-2.82c0.02-3.25-6.1-3.55-6.29-6.8c4.41-1.19,8.71-2.76,12.85-4.7
                	c-0.14-3,3.11-5.11,6.1-5.39c1.54-0.09,3.08-0.02,4.6,0.19c1.45,0.2,2.91,0.27,4.37,0.21c2.99-0.21,6.3-2.17,6.34-5.17
                	c0.02-1.53-0.78-3.25,0.13-4.49c1.54-2.09,6.31-0.4,6.65-2.98c0.31-2.43-3.64-1.93-6.01-2.55c-2.15-0.69-3.33-2.99-2.64-5.14
                	c0.05-0.16,0.12-0.32,0.19-0.48c0.59-1.1,1.68-1.87,2.23-2.99s0.15-2.89-1.1-2.94c-5.47,0.78-8.21,7.49-7.32,12.94
                	c0.3,1.82,0.87,3.68,0.46,5.49s-2.33,3.44-4.02,2.71c-1.49-0.64-1.87-2.56-3.01-3.71c-1.47-1.5-4-1.5-5.49-2.98
                	c-1.84-1.84-1.2-5.04,0.31-7.16s3.69-3.72,5.01-5.96c2.12-3.58,1.73-8.23,3.86-11.79c2.5-4.17,7.95-5.77,12.76-5.12
                	s9.15,3.13,13.36,5.56c2.3,1.33,4.91,4.17,3.14,6.15c-1.01,1.14-2.63,0.91-4.27,0.68c-1.58-0.22-3.17-0.44-4.24,0.56
                	c0.13,3.23,3.96,5.25,7.15,4.72s5.77-2.78,8.18-4.94c3.2-2.87,6.4-5.75,9.6-8.62c3.22-2.89,8.29-5.81,11.39-2.79
                	c1.61,1.56,8.42,0.64,9.72-1.19s1.32-4.24,1.38-6.48s0.32-4.69,1.9-6.28c1.33-1.33,3.46-1.9,4.25-3.61c0.55-1.2,0.26-2.6,0.37-3.91
                	c0.41-4.67,5.6-7.16,10.06-8.61c2.03-0.81,4.2-1.2,6.38-1.15c2.17,0.19,4.39,1.44,5.08,3.51c0.65,1.98,0.08,4.75,1.94,5.7
                	c5.1-4.05,11.95-5.11,18.03-2.78c3.67,1.45,7.15,4.16,11.07,3.68c5.55-0.67,8.35-7.16,13.3-9.76c3.39-1.78,7.43-1.56,11.25-1.3
                	l12.79,0.88c2.37,0.29,4.78,0.19,7.12-0.29c1.57-0.41,3.02-1.16,4.56-1.67c7.9-2.59,16.41,1.73,18.99,9.63
                	c0.64,1.95,0.87,4.02,0.68,6.07c-0.25,1.31-0.29,2.65-0.12,3.97c0.74,3.22,4.89,5.15,4.77,8.45c-0.15,4.08-6.2,4.89-8.38,8.33
                	c-1.44,2.27-0.92,5.19-1.09,7.87s-1.9,5.84-4.56,5.54c-2.18-0.25-3.42-2.81-3.2-5s1.42-4.12,2.28-6.15s1.33-4.47,0.15-6.33
                	c-1.22-1.93-3.77-2.5-5.7-1.28c-1.21,0.76-1.93,2.1-1.92,3.53c0.08,0.64,0.09,1.29,0.04,1.94c-0.19,1.01-0.74,2.03-1.77,2.05
                	c-3.87,3.09-8.94,4.27-13.08,6.98c-4.15,2.71-7.35,8.33-4.75,12.55c2.12,3.45,6.93,4.14,9.76,7.03c1.51,1.68,2.51,3.77,2.88,6
                	c2.39,11.7-7.47,24.33-19.4,24.83c-0.92-0.03-1.83,0.09-2.7,0.38c-3.15,1.32-2.35,6.02-0.73,9.03c1.9,3.53,4.22,6.93,5.04,10.85
                	s-0.38,8.64-3.96,10.44c-1.91,0.78-3.98,1.09-6.04,0.92c-1.28-0.04-2.57,0.02-3.84,0.2c-3.29,0.56-6.55,4.5-4.29,6.97
                	c2.51,1.4,4.95,3.48,5.45,6.31s-1.99,6.15-4.78,5.48c-2.81-0.68-4.16-4.67-7.05-4.7c-2.08,1.64-0.64,4.93,0.82,7.14
                	s2.53,5.78,0.18,7c-4.32-5.51-6.68-12.3-6.73-19.3l-4.11,0.59c-2.54-1.73-2.98-5.21-4.19-8.03s-4.87-5.37-7.13-3.29
                	c-1.8,1.66-1,4.87-2.54,6.78c-1.23,1.51-3.52,1.7-4.92,3.05c-1.78,1.73-1.49,4.58-1.68,7.05s-1.89,5.45-4.31,4.91
                	c-2.39-8.33-7.02-15.84-13.38-21.72c-0.69-0.68-1.47-1.27-2.32-1.75c-1.27-0.56-2.62-0.92-4.01-1.08
                	c-5.5-0.95-10.85-2.62-15.92-4.95c-2.83-1.3-6.59-2.64-8.66-0.32c3.02,3.53,7.04,7.05,11.66,6.57c0.76-0.15,1.53-0.2,2.3-0.14
                	c1.96,0.34,2.95,2.8,2.41,4.71s-2.12,3.34-3.72,4.52c-5.02,3.74-10.83,6.26-16.99,7.39c-3-6.36-6.41-12.51-10.22-18.41
                	c-1.07-1.65-3.83-3.19-4.52-1.35c-0.16,0.72-0.02,1.47,0.38,2.08c2.37,4.96,4.75,9.92,7.12,14.88c0.69,1.7,1.69,3.26,2.94,4.6
                	c3.84,3.63,10.01,2.28,15.05,0.69c-0.99,6.79-4.65,12.9-10.16,16.99c-3.63,2.66-8.42,5.02-9,9.48c-0.36,2.78,1.13,5.41,2.16,8.02
                	s1.47,5.94-0.53,7.91c-0.99,0.8-2.1,1.44-3.29,1.89c-4.25,2.21-6.99,6.53-7.19,11.32c0.09,1.57,0.05,3.14-0.14,4.69
                	c-1.05,4.96-6.95,6.76-12.18,6.76C235.01,226.93,234.52,226.91,234.03,226.88L234.03,226.88z M345.82,177.89
                	c-1.36-2.48-3.3-5.06-4.42-7.4c4.28-2.04,11.12,6.46,12.87,9.84c0.55,1.2,1.17,2.37,1.85,3.5c1.77,2.62,4.72,4.2,7.7,5.25
                	s6.11,1.66,9.02,2.89c1.05,0.44,2.23,1.69,1.43,2.51c-0.13,0-0.27,0-0.41,0C362.23,194.33,351.55,188.02,345.82,177.89
                	L345.82,177.89z M364.31,181.78c-1.73-0.59-3.25-1.66-4.4-3.08c-4.44-6.46,9.86-7.97,13.73-7.18c2.39,0.48,3.19,0.35,2.64,2.74
                	c-0.29,1.24-4.43,6.98-5.67,7.82c-0.71,0.46-1.54,0.69-2.38,0.67C366.87,182.68,365.54,182.35,364.31,181.78L364.31,181.78z
                	 M145.25,69.7c-2.26-0.54-3.93-2.44-5.15-4.41c-1.32-2.01-2.18-4.29-2.52-6.67c-0.6-5.05,1.87-9.87,3.63-14.64s2.67-10.6-0.54-14.54
                	c-2-2.45-5.15-3.6-7.67-5.5s-4.46-5.51-2.73-8.16c2.47-3.76,8.13-1.61,11.2-4.36c3.54-3.17,1.96-8.51,7.37-10.78
                	c3.51-1.47,7.51,0.06,11.14,0.23c2.12,0.1,4.23,0.02,6.34-0.06c4.5-0.17,8.96-0.34,13.4,1.22c5.26,1.86,9.96,6.5,10.09,12.08
                	c0,1.73-0.32,3.45-0.93,5.07c-2.92,8.34-10.38,14.71-12.28,23.34c-1.13,5.14-0.52,11.39-4.64,14.67c-3.33,2.65-8.24,1.95-12.05,3.82
                	c-2.65,1.3-4.54,3.71-6.71,5.71c-1.84,1.68-4.19,3.15-6.61,3.15C146.13,69.86,145.68,69.81,145.25,69.7z'
          />
          <path
            className='small-path-circle'
            id='Path_22813u'
            fill='#E8EFFF'
            d='M210.96,102.23c0,1.11-0.9,2.01-2.01,2.01c-1.11,0-2.01-0.9-2.01-2.01
                	c0-1.11,0.9-2.01,2.01-2.01C210.06,100.22,210.96,101.12,210.96,102.23C210.96,102.23,210.96,102.23,210.96,102.23z'
          />
          <path
            className='small-path-circle'
            id='Path_22815u'
            fill='#E8EFFF'
            d='M332.84,117.34c0,1.11-0.9,2.01-2.01,2.01c-1.11,0-2.01-0.9-2.01-2.01
                	c0-1.11,0.9-2.01,2.01-2.01C331.94,115.33,332.84,116.23,332.84,117.34z'
          />
          <path
            className='small-path-circle'
            id='Path_22818u'
            fill='#E8EFFF'
            d='M125.36,200.43c0,1.11-0.9,2.01-2.01,2.01c-1.11,0-2.01-0.9-2.01-2.01
                	c0-1.11,0.9-2.01,2.01-2.01S125.36,199.32,125.36,200.43L125.36,200.43z'
          />
          <path
            className='small-path-circle'
            id='Path_22819u'
            fill='#E8EFFF'
            d='M69.96,102.23c0,1.11-0.9,2.01-2.01,2.01s-2.01-0.9-2.01-2.01s0.9-2.01,2.01-2.01
                	C69.06,100.22,69.96,101.12,69.96,102.23z'
          />
          <path
            className='small-path-circle'
            id='Path_22840u'
            fill='#E8EFFF'
            d='M234.13,164.17c0,1.11-0.9,2.01-2.02,2.01c-1.11,0-2.01-0.9-2.01-2.02
                	c0-1.11,0.9-2.01,2.01-2.01C233.23,162.16,234.13,163.06,234.13,164.17C234.13,164.17,234.13,164.17,234.13,164.17z'
          />
          <path
            className='small-path path-12'
            fill='none'
            stroke='#E8EFFF'
            d='M237.15,97.19c0,0-14.96-2.62-28.56,5.21'
          />
          <path
            className='small-path path-10'
            fill='none'
            stroke='#E8EFFF'
            d='M331.15,117.47c-39.91-34.97-94-20.28-94-20.28'
          />
          <path
            className='small-path path-11'
            fill='none'
            stroke='#E8EFFF'
            d='M237.15,97.19c0,0-28.65,22.98-4.93,67.14'
          />
          <path
            className='small-path path-7'
            fill='none'
            stroke='#E8EFFF'
            d='M67.67,102.33c88.87-50.49,169.49-5.14,169.49-5.14'
          />
          <path
            className='small-path path-8'
            id='Path_22936'
            fill='none'
            stroke='#E8EFFF'
            d='M237.15,97.2c0,0-97.85-33.98-113.84,103.45'
          />
          <path
            id='Path_22820'
            fill='#E8EFFF'
            d='M241.18,97.19c0,2.23-1.8,4.03-4.03,4.03c-2.23,0-4.03-1.8-4.03-4.03
                	c0-2.23,1.8-4.03,4.03-4.03l0,0C239.38,93.17,241.18,94.97,241.18,97.19z'
          />
          {/* <path
            className='small-path-circle'
            id='Path_22971u'
            fill='#E8EFFF'
            d='M277.44,77.05c0,1.11-0.9,2.01-2.01,2.01c-1.11,0-2.01-0.9-2.01-2.01
                	c0-1.11,0.9-2.01,2.01-2.01C276.54,75.04,277.44,75.94,277.44,77.05z'
          /> */}
          {/* <path
            className='small-path path-9'
            id='Path_22972u'
            fill='none'
            stroke='#E8EFFF'
            d='M275.43,77.05l-38.27,20.14'
          /> */}
          <text
            className='small-path-name'
            transform='matrix(1 0 0 1 57.8424 118.0005)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            North
          </text>
          <text
            className='small-path-name'
            transform='matrix(1 0 0 1 50.1004 136.0005)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            America
          </text>
          <text
            className='small-path-name'
            transform='matrix(1 0 0 1 105.5134 216.0005)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            South
          </text>
          <text
            className='small-path-name'
            transform='matrix(1 0 0 1 98.1004 234.0005)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            America
          </text>
          <text
            className='small-path-name'
            transform='matrix(1 0 0 1 214.0004 180.0005)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            Africa
          </text>
          <text
            transform='matrix(1 0 0 1 199.5004 77.0005)'
            fill='#E8EFFF'
            fontSize='18px'
          >
            Lithuania
          </text>
          <text
            className='small-path-name'
            transform='matrix(1 0 0 1 318.0004 133.0005)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            Asia
          </text>
          {/* <text
            className='small-path-name'
            transform='matrix(1 0 0 1 255.0004 93.0005)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            Russia
          </text> */}
          <text
            className='small-path-name'
            transform='matrix(1 0 0 1 174.0004 118.0005)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            Europe
          </text>
        </svg>

        <svg
          className='mobile-hide svg'
          data-sal='fade'
          data-sal-duration='1000'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 833.99 539.99'
        >
          <path
            id='map'
            opacity='0.1'
            fill='#E8EFFF'
            d='M233.98,539.79c-16.2-6.57-28.29-21.47-33.46-38.18s-3.86-34.99,1.54-51.63
                	c2.89-8.9,6.94-17.5,8.44-26.75s-0.06-19.66-6.85-26.09c-7.34-6.95-15.47-10.59-22.24-18.1c-3.64-4.04-7.29-8.31-9.01-13.48
                	s-1.1-11.48,2.9-15.17c2.3-2.13,5.66-3.42,6.77-6.35c1.38-3.64-3.08-8.02-1.94-11.74c-4.97,0.4-9.81-1.65-13-5.48
                	c-1.03-1.5-2.15-2.93-3.35-4.3c-5.07-4.9-14.3-2.86-19.31-7.82c-1.04-1.02-1.82-2.3-2.9-3.27c-2.35-2.12-5.7-2.57-8.81-3.14
                	c-6-1.09-13.83-2.44-18.6-6.24c-6.59-5.27-11.35-12.47-13.62-20.6c-1.29-4.66-1.72-9.53-3.18-14.14
                	c-2.91-9.18-10.19-17.03-18.64-21.64c-8.07-4.41-15.98-11.28-16.82-20.44c-0.46-5.05,1.32-10.03,1.56-15.1
                	c0.13-6.82-1.85-13.51-5.66-19.17c-3.48-4.92-4.11-11.24-4.63-17.25c-0.42-4.88-0.83-9.9,0.63-14.57c1.55-4.99,5.07-9.08,7.52-13.7
                	s3.72-10.57,0.75-14.86c-2.23-3.22-6.25-4.63-10.08-5.49c-11.06-2.49-24.62-6.24-33.36,1c-3.31,2.74-8.21,1.81-12.38,0.74
                	c-1.95-0.5-4.17-1.25-4.86-3.14c-0.6-1.65,0.26-3.43,0.79-5.11c1.4-4.4,0.94-10.56-1.97-14.14c-0.48-5.32-0.44-11.77,4.07-14.63
                	c1.52-0.77,3.08-1.46,4.68-2.07c1.52-0.77,2.96-2.14,2.97-3.85c0.02-2.15-2.09-3.59-3.33-5.34c-1.59-2.25,0.06-3.5,1.43-5.89
                	c-0.77-1.94,0.97-4.11,2.96-4.73s4.14-0.21,6.23-0.12c3.55,0.16,7.84,0.07,11-1.54c0.61-3.86-2.64-7.11-5.25-10.02
                	s-6.03-9.04-3-11.51c6.87-5.6,16.62-8.58,25.17-6.26c6.74-1.53,18.12,1.15,20.02,7.8c1.19,4.16,5.7,6.23,9.56,8.19
                	c5.88,3.08,11.28,7.01,16.01,11.66c4.51-0.3,10.65-1.18,13.93-4.29c3.74-0.38,7.52,0.12,11.03,1.44c2.57,0.97,6.39,0.86,10.29,0.76
                	c4.72-0.13,9.54-0.26,12.34,1.53c7.78,4.96,12.46,14.79,21.41,17.05c3.17,0.8,6.49,0.51,9.75,0.66
                	c11.14,0.49,22.37,6.91,26.55,17.26s-0.09,23.56-10.56,27.4c-8.65-2.92-19.17,2.65-21.63,11.44s3.65,19.01,12.55,21.01
                	c2.61,0.59,5.4,0.56,7.82,1.7c3.16,1.49,5.1,4.67,7.56,7.15c1.72,1.74,3.9,3.22,6.34,3.29c4.68,0.12,7.93-5.05,8.06-9.73
                	s-1.79-9.16-2.54-13.78s0.13-10.16,4.16-12.54c4.12-2.44,9.73-0.23,12.65,3.57s3.82,8.73,4.63,13.45c4.13-2.13,9.75-2,14.38-2.22
                	c2.96,3.51,3.23,8.46,4.13,12.96c1,4.91,2.91,9.59,5.64,13.8c1.98,3.04,4.41,5.89,5.44,9.37c2.16,7.31-3.78,15.86-11.39,16.37
                	c-1.86,0.06-3.72-0.07-5.56-0.37c-3.52-0.46-6.99-0.92-9.76,1.07c-2.83,2.04-3.63,5.83-5.41,8.84c-2.46,4.16-4.94,7.19-9.77,6.99
                	c-5.56-0.23-11.1,0.76-16.24,2.91c-1.85,0.77-3.79,1.87-4.44,3.77c-0.82,2.42,0.82,4.9,1.53,7.35c1.33,4.55-0.68,9.59-4.05,12.93
                	s-6.69,8.07-11.43,8.01c-4.67,0.04-8.8,3.05-10.29,7.47c-0.59,1.87-0.65,3.87-1.06,5.79c-0.28,1.97-1.38,3.73-3.01,4.85
                	c-1.7,0.97-4.85,0.75-4.87-1.21c-0.09-7.74-8.25-13.4-15.99-13.62c-7.89-0.23-16,4.26-19.1,11.52c-3.1,7.27-0.2,16.85,6.85,20.39
                	c3.62,1.82,7.82,2,11.88,2.13s8.25,0.27,11.9,2.02s6.66,5.57,6.12,9.59c-0.34,2.52-1.82,5.56,0.08,7.24
                	c1.15,1.01,2.92,0.79,4.34,1.38c2.65,1.1,3.27,4.49,4.81,6.91c2.14,3.36,9.12,4.49,12.89,3.19c0.65-8.26,11.12-13.96,18.4-10.02
                	c3.51,1.9,6.68,5.6,10.6,4.85c1.43-0.27,2.67-1.14,4.05-1.59c3.5-1.15,7.27,0.42,10.61,1.96c8.88,4.09,18.05,8.4,24.36,15.87
                	c3.74,4.43,6.41,9.9,11.15,13.24c5.17,3.66,11.86,4.13,18.18,4.31s12.95,0.27,18.48,3.34s9.47,10.35,8.05,16.52
                	c-1.25,5.41-3.62,10.5-6.97,14.94c-2.82,3.74-6.47,7.33-6.91,12c-0.31,3.32,1.1,6.52,1.65,9.81c0.55,2.67,0.21,5.45-0.95,7.92
                	c-1.25,2.4-3.03,4.89-5.7,5.32c-2.4,0.29-4.82,0.34-7.23,0.14c-4.48-0.21-8.87-0.43-12.12,2.38c-5.49,4.73-2.86,13.5-3.46,20.73
                	c-0.75,9.03-7.46,16.81-15.62,20.74c-1.93,0.93-4.02,1.73-5.43,3.33c-3.05,3.46-2.38,9.9-6.6,11.76c-1.28,0.57-2.76,0.52-4.09,0.98
                	c-2.92,0.99-4.58,4.24-4.69,7.32s1.69,6.1,1.77,9.18c0.08,3.31-1.02,6.54-3.11,9.11c-1.37,1.68-3.22,3.23-3.4,5.39
                	c-0.15,1.92,1.1,3.63,2.3,5.15c3.79,4.81,9,9.41,11.92,14.79c0.91,1.76,0.65,3.91-0.68,5.39c-1.06,1.24-2.56,2.01-4.18,2.16
                	C234.71,540,234.33,539.93,233.98,539.79z M786.86,475.78c-4.93-7.68-5.69-18.76-13.7-23.13c-7.48-4.09-16.56,0.3-24.06,4.37
                	s-17.18,7.73-24,2.62c-1.57-1.03-2.59-2.71-2.78-4.58c-0.03-1.54,0.79-2.95,1.35-4.38c2.79-7.14-0.71-14.99-2.41-22.47
                	s0.04-17.52,7.5-19.25c2.54-0.59,5.25-0.02,7.78-0.62c4.29-1.02,7.04-5.04,9.98-8.33c6.36-7.12,18.05-14.21,28.05-12.92
                	c8.21,1.05,14.18,12.02,21.56,11.76c-2.57-11.15,6.86-10.31,11.47-2.44c2.3,3.93,2.95,8.58,3.55,13.09
                	c11.32,8.67,23.86,20.04,22.19,34.2c-0.86,7.33-5.51,13.58-10,19.45c-3.39,4.43-6.78,8.87-10.17,13.3
                	c-1.53,2.26-3.45,4.24-5.66,5.84c-1.88,1.18-4.07,1.8-6.29,1.78C795.73,484.04,789.96,480.6,786.86,475.78z M464.72,450.52
                	c-2.16,0.02-4.28-0.52-6.16-1.58c-3.88-2.59-3.87-8.12-4.71-12.71c-3.11-17.05-22.82-30.67-18.11-47.36
                	c1.03-3.64,3.23-7.02,3.31-10.81c0.15-7.31-7.21-12.17-11.16-18.32c-3.67-5.83-4.35-13.06-1.83-19.47
                	c-2.21,2.66-6.64,1.56-9.31-0.64s-4.71-5.3-7.91-6.62c-3.23-1.33-6.88-0.56-10.35-0.12c-15.14,1.92-31.46-3.82-40.7-15.98
                	s-10.04-30.59-0.52-42.53c8.74-10.97,24.41-15.66,30.82-28.14c10.02,6.98,22.34-5.55,34.47-6.93c2.8-0.32,6.07,0.24,7.53,2.65
                	c1.02,1.69,0.86,3.82,1.27,5.76c1.54,7.19,10.53,10.04,17.83,9.19s14.4-3.99,21.7-3.26c6.62,0.66,12.69,4.47,19.34,4.67
                	s14.18-6.51,10.84-12.26c-2.43-4.19-7.68-3.86-13.01-3.53c-5.12,0.32-10.3,0.64-13.15-3.03c-2.01-2.59-1.88-6.25-3.29-9.22
                	s-6.3-4.68-7.64-1.68c2.47,3.62,0.1,9.44-4.2,10.29c-2.93-12.79-12.47-23.04-25.01-26.9c-2.06,3.18,0.13,7.41,2.77,10.13
                	s5.95,5.33,6.46,9.09s-4.66,7.62-6.98,4.62c-0.92-8.3-5.81-15.64-13.11-19.68c-1.69,2.76-5.55,2.81-8.71,3.53
                	c-5.69,1.38-10.21,5.71-11.81,11.35c-0.67,2.47-0.8,5.23-2.4,7.23c-1.67,2.1-4.54,2.81-7.21,3.02c-6.67,0.53-14.04-1.72-17.62-7.37
                	s-1.31-14.75,5.16-16.47c3.75-0.99,7.64,0.55,11.51,0.83s8.66-1.73,8.69-5.61c0.05-6.46-12.11-7.06-12.48-13.5
                	c8.76-2.36,17.29-5.49,25.51-9.33c-0.27-5.95,6.18-10.15,12.11-10.69c3.05-0.18,6.11-0.05,9.14,0.38c2.87,0.4,5.78,0.54,8.68,0.42
                	c5.94-0.42,12.51-4.3,12.59-10.26c0.04-3.05-1.56-6.46,0.25-8.91c3.06-4.16,12.54-0.8,13.19-5.92c0.62-4.82-7.23-3.83-11.94-5.06
                	c-4.51-1.18-7.07-7.04-4.87-11.16c1.17-2.18,3.33-3.72,4.44-5.93s0.29-5.75-2.18-5.84c-10.86,1.55-16.29,14.86-14.54,25.7
                	c0.59,3.62,1.72,7.31,0.92,10.89s-4.62,6.83-7.99,5.39c-2.95-1.26-3.72-5.08-5.97-7.38c-2.92-2.97-7.95-2.98-10.9-5.92
                	c-3.66-3.65-2.38-10.01,0.61-14.22s7.33-7.39,9.96-11.84c4.21-7.1,3.43-16.34,7.67-23.42c4.96-8.27,15.79-11.47,25.34-10.17
                	s18.18,6.22,26.53,11.04c4.57,2.64,9.75,8.28,6.24,12.22c-2.01,2.26-5.23,1.8-8.48,1.34c-3.13-0.44-6.3-0.88-8.42,1.12
                	c0.26,6.41,7.87,10.43,14.2,9.37s11.47-5.53,16.24-9.82c6.36-5.71,12.71-11.41,19.06-17.12c6.39-5.74,16.45-11.54,22.61-5.54
                	c3.19,3.11,16.73,1.28,19.31-2.36s2.62-8.41,2.75-12.86s0.63-9.32,3.77-12.47c2.64-2.64,6.87-3.77,8.44-7.16
                	c1.1-2.38,0.51-5.16,0.74-7.77c0.82-9.28,11.12-14.21,19.97-17.11c4.11-1.34,8.37-2.66,12.67-2.28s8.72,2.85,10.08,6.96
                	c1.3,3.94,0.16,9.43,3.85,11.32c10.12-8.04,23.73-10.14,35.8-5.53c7.28,2.88,14.2,8.25,21.97,7.31
                	c11.02-1.33,16.59-14.21,26.42-19.38c6.72-3.53,14.75-3.11,22.33-2.59l25.4,1.74c4.73,0.32,9.56,0.64,14.15-0.57
                	c3.11-0.82,6-2.31,9.05-3.31c9.58-3.03,20.03-1.14,27.94,5.04c7.79,6.34,11.95,16.12,11.13,26.14c-0.24,2.63-0.83,5.32-0.23,7.89
                	c1.48,6.39,9.71,10.23,9.46,16.78c-0.31,8.1-12.31,9.7-16.65,16.54c-2.86,4.5-1.82,10.3-2.17,15.62s-3.77,11.6-9.06,11.01
                	c-4.33-0.49-6.79-5.58-6.35-9.92s2.83-8.19,4.52-12.2s2.63-8.88,0.3-12.56c-2.01-3.03-5.73-4.43-9.24-3.48
                	c-3.47,1.1-5.84,4.3-5.88,7.94c0.15,1.28,0.18,2.57,0.09,3.85c-0.38,2.02-1.47,4.02-3.52,4.08c-7.69,6.14-17.75,8.48-25.98,13.87
                	s-14.6,16.52-9.45,24.91c4.2,6.86,13.75,8.21,19.38,13.96c3.12,3.19,4.81,7.54,5.71,11.92c4.75,23.23-14.84,48.31-38.52,49.31
                	c-1.82-0.07-3.64,0.18-5.37,0.75c-6.26,2.63-4.67,11.95-1.45,17.93c3.77,7,8.38,13.76,10,21.54s-0.76,17.16-7.86,20.73
                	c-3.67,1.84-7.82,1.83-12,1.82c-2.55-0.08-5.1,0.05-7.62,0.4c-6.54,1.11-13,8.93-8.53,13.83c4.99,2.77,9.82,6.91,10.82,12.53
                	s-3.96,12.22-9.5,10.87c-5.58-1.35-8.25-9.27-13.99-9.33c-4.14,3.25-1.28,9.79,1.63,14.17s5.02,11.47,0.36,13.91
                	c-8.57-10.94-13.27-24.42-13.35-38.32c-2.72,0.39-5.44,0.78-8.17,1.18c-5.05-3.43-5.92-10.34-8.32-15.95s-9.66-10.67-14.15-6.54
                	c-3.58,3.3-1.98,9.68-5.05,13.46c-2.43,3-7,3.37-9.77,6.06c-3.53,3.43-2.96,9.09-3.33,13.99s-3.76,10.83-8.56,9.75
                	c-4.75-16.54-13.94-31.46-26.57-43.15c-1.38-1.36-2.93-2.53-4.61-3.47c-2.53-1.11-5.21-1.83-7.96-2.14
                	c-10.91-1.9-21.53-5.2-31.6-9.82c-5.61-2.58-13.08-5.25-17.2-0.64c6,7.01,13.97,14,23.15,13.04c1.5-0.3,3.03-0.39,4.56-0.28
                	c3.89,0.68,5.85,5.55,4.78,9.35s-4.21,6.62-7.38,8.99c-9.96,7.41-21.5,12.43-33.71,14.67c-5.95-12.62-12.73-24.83-20.29-36.56
                	c-2.12-3.28-7.61-6.34-8.98-2.68c-0.51,1.35,0.12,2.83,0.75,4.13c4.71,9.85,9.43,19.7,14.14,29.55c1.57,3.28,3.2,6.64,5.84,9.14
                	c7.62,7.2,19.88,4.52,29.88,1.38c-1.97,13.48-9.24,25.62-20.18,33.73c-7.21,5.28-16.72,9.97-17.87,18.83
                	c-0.71,5.52,2.25,10.75,4.29,15.93s2.92,11.8-1.05,15.71c-1.8,1.77-4.28,2.62-6.54,3.76c-8.44,4.4-13.89,12.96-14.29,22.47
                	c-0.09,3.11,0.37,6.27-0.28,9.32c-2.09,9.84-13.81,13.43-24.19,13.43C466.65,450.61,465.68,450.58,464.72,450.52z M686.69,353.24
                	c-2.7-4.92-6.56-10.05-8.77-14.7c8.5-4.05,22.08,12.83,25.56,19.53c1.09,2.38,2.32,4.7,3.67,6.95c3.51,5.2,9.37,8.34,15.29,10.42
                	s12.14,3.29,17.92,5.74c2.09,0.88,4.43,3.36,2.85,4.98c-0.27,0-0.54,0.01-0.81,0.01C719.77,386.16,697.58,373.07,686.69,353.24z
                	 M723.41,360.96c-3.32-1.41-6.69-3.15-8.73-6.12c-8.82-12.82,19.57-15.83,27.27-14.27c4.75,0.96,6.34,0.69,5.23,5.45
                	c-0.57,2.47-8.8,13.86-11.27,15.53c-1.4,0.91-3.05,1.38-4.72,1.33C728.49,362.75,725.85,362.1,723.41,360.96L723.41,360.96z
                	 M288.41,138.41c-4.48-1.08-7.8-4.85-10.23-8.76c-2.61-4-4.32-8.52-5-13.25c-1.2-10.02,3.72-19.6,7.21-29.07s5.3-21.05-1.07-28.88
                	c-3.96-4.87-10.23-7.14-15.24-10.93s-8.86-10.95-5.42-16.2c4.9-7.48,16.14-3.21,22.23-8.65c7.04-6.29,3.89-16.9,14.63-21.41
                	c6.97-2.93,14.92,0.12,22.12,0.45c4.21,0.19,8.41,0.03,12.58-0.12c8.94-0.34,17.8-0.68,26.6,2.43c10.45,3.69,19.79,12.9,20.04,23.99
                	c-0.01,3.44-0.63,6.85-1.84,10.08c-5.8,16.55-20.61,29.21-24.38,46.34c-2.25,10.2-1.04,22.62-9.21,29.13
                	c-6.6,5.26-16.34,3.88-23.92,7.59c-5.26,2.58-9.01,7.37-13.33,11.33c-3.65,3.34-8.32,6.25-13.12,6.25
                	C290.18,138.73,289.29,138.62,288.41,138.41L288.41,138.41z'
          />
          <path
            className='path-circle'
            id='Path_22813'
            fill='#E8EFFF'
            d='M420,203c0,2.21-1.79,4-4,4s-4-1.79-4-4s1.79-4,4-4S420,200.79,420,203z'
          />
          <path
            className='path-circle'
            id='Path_22815'
            fill='#E8EFFF'
            d='M661.99,233c0,2.21-1.79,4-4,4c-2.21,0-4-1.79-4-4s1.79-4,4-4
                	C660.2,229,661.99,230.79,661.99,233z'
          />
          <path
            className='path-circle'
            id='Path_22818'
            fill='#E8EFFF'
            d='M250,398c0,2.21-1.79,4-4,4s-4-1.79-4-4s1.79-4,4-4S250,395.79,250,398z'
          />
          <path
            className='path-circle'
            id='Path_22819'
            fill='#E8EFFF'
            d='M140,203c0,2.21-1.79,4-4,4s-4-1.79-4-4s1.79-4,4-4S140,200.79,140,203z'
          />
          <path
            className='path-circle'
            id='Path_22840'
            fill='#E8EFFF'
            d='M466,326c0,2.21-1.79,4-4,4s-4-1.79-4-4s1.79-4,4-4S466,323.79,466,326z'
          />
          <path
            className='path path-6'
            fill='none'
            stroke='#E8EFFF'
            d='M472,193c0,0-29.7-5.2-56.71,10.35'
          />
          <path
            className='path path-4'
            fill='none'
            stroke='#E8EFFF'
            d='M658.65,233.27C579.4,163.83,472,193,472,193'
          />
          <path
            className='path path-5'
            fill='none'
            stroke='#E8EFFF'
            d='M472,193c0,0-56.89,45.64-9.79,133.33'
          />
          <path
            className='path path-1'
            fill='none'
            stroke='#E8EFFF'
            d='M135.45,203.2C311.91,102.95,472,193,472,193'
          />
          <path
            className='path path-2'
            id='Path_22936'
            fill='none'
            stroke='#E8EFFF'
            d='M472,193c0,0-194.3-67.48-226.05,205.42'
          />
          <path
            id='Path_22820'
            fill='#E8EFFF'
            d='M480,193.01c0,4.42-3.58,8-8,8s-8-3.58-8-8s3.58-8,8-8S480,188.59,480,193.01z'
          />
          {/* <path
            className='path-circle'
            id='Path_22971'
            fill='#E8EFFF'
            d='M551.99,153c0,2.21-1.79,4-4,4c-2.21,0-4-1.79-4-4s1.79-4,4-4
                	C550.2,149,551.99,150.79,551.99,153z'
          /> */}
          {/* <path
            className='path path-3'
            id='Path_22972'
            fill='none'
            stroke='#E8EFFF'
            d='M548,153c0,0-40.5,0.29-76,40'
          /> */}
          <text
            className='path-name'
            transform='matrix(1 0 0 1 89.9974 229.0041)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            North America
          </text>
          <text
            className='path-name'
            transform='matrix(1 0 0 1 199.9974 424.0041)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            South America
          </text>
          <text
            className='path-name'
            transform='matrix(1 0 0 1 442.9974 352.0041)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            Africa
          </text>
          <text
            transform='matrix(1 0 0 1 423.4974 167.0041)'
            fill='#E8EFFF'
            fontSize='24px'
          >
            Lithuania
          </text>
          <text
            className='path-name'
            transform='matrix(1 0 0 1 643.9974 259.0041)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            Asia
          </text>
          {/* <text
            className='path-name'
            transform='matrix(1 0 0 1 526.9974 179.0041)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            Russia
          </text> */}
          <text
            className='path-name'
            transform='matrix(1 0 0 1 393.9974 229.0041)'
            fill='#E8EFFF'
            fontSize='14px'
          >
            Europe
          </text>
        </svg>
      </div>
      <Qualities data={texts} />
      <div className='wrap-full pl-xl pr-xl layer-slider-top'>
        <h2
          className='h2'
          data-sal='slide-right'
          data-sal-duration='1000'
          dangerouslySetInnerHTML={{ __html: data.products_slider_title }}
        />
      </div>
      <ProductSwiper pages={pages} lang={lang} texts={texts} />
    </Layout>
  );
};
